import React from "react"
import { Link } from "gatsby"

const ProjectCategory = ({ category, list, click }) => {
  return (
    <div className="category">
      <div className="category__title">category</div>
      <ul className="category__list">
        {list.map(v => {
          if (v === "installation") {
            return (
              <li className="category__item" key={`${v}`}>
                <Link
                  className={
                    category === v
                      ? "is-current category__btn"
                      : "category__btn"
                  }
                  to="/projects/installation"
                >
                  {v}
                </Link>
              </li>
            )
          }

          return (
            <li className="category__item" key={`${v}`}>
              <button
                className={
                  category === v ? "is-current category__btn" : "category__btn"
                }
                type="button"
                data-category={`${v}`}
                onClick={click}
              >
                {v}
              </button>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default ProjectCategory
