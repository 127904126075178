import React from "react"
import { Link } from "gatsby"

const ProjectItem = ({ v, i, currentCategory }) => {
  const category = v.data.project_category
  const thumbnailPath = v.data.thumbnail.url
  const titleEn = v.data.title_en
  const titleJa = v.data.title_ja
  const slug = v.uid

  //カテゴリーの絞り込み
  const articleClass = ["projects-post", "projects-area__item"]
  if (currentCategory !== "all" && currentCategory !== category) {
    articleClass.push("is-hidden")
  }

  return (
    <article
      data-scroll=""
      className={articleClass.join(" ")}
      data-category={category}
    >
      <Link className="projects-post__link" to={`/projects/${slug}`}>
        <div className="projects-post__img">
          <img width="376" height="270" src={thumbnailPath} alt="" />
        </div>
        <div className="projects-post__text">
          <h2 className="projects-post__title">
            <span
              className="projects-post__title-1 en"
              dangerouslySetInnerHTML={{ __html: titleEn[0].text }}
            />
            <span
              className="projects-post__title-2 ja"
              dangerouslySetInnerHTML={{ __html: titleJa[0].text }}
            />
          </h2>
          <span className="projects-post__category en">{category}</span>
        </div>
      </Link>
    </article>
  )
}

const ProjectList = props => {
  const projectList = []
  const currentCategory = props.category
  const articles = props.articles
  if (!articles) {
    return false
  }

  //日にちでソート
  articles.sort(function(before, next) {
    if (before.data.order_date > next.data.order_date) {
      return -1
    } else {
      return 1
    }
  })

  articles.forEach((v, i) => {
    projectList.push(
      <ProjectItem key={i} v={v} i={i} currentCategory={currentCategory} />
    )
  })

  return projectList
}

export default ProjectList
