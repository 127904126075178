import React, { useState, useEffect } from "react"
import Layout from "../../components/common/layout"
import Meta from "../../components/common/meta"
import { Link } from "gatsby"
import PageTitle from "../../components/pageTitle/pageTitle"
import ProjectCategory from "../../components/pages/project/archive/projectCategory"
import ProjectList from "../../components/pages/project/archive/projectList"

const categoryList = [
  "all",
  "on going",
  "landscape",
  "zen garden",
  // "installation",
  // "personal work",
  "proposal",
]

const Projects = props => {
  const [category, setCategory] = useState("all")
  const context = props.pageContext.data.results

  useEffect(() => {
    //urlにパラメータがあるときはカテゴリーを絞り込む
    const query = window.location.search
    if (query) {
      let para = query.split("=")
      let cat = para[1]
      switch (cat) {
        case "on":
          cat = "on going"
          break
        case "zen":
          cat = "zen garden"
          break
        case "personal":
          cat = "personal work"
          break
        default:
          break
      }
      setCategory(cat)
    }
  }, [])

  const categorySwitch = e => {
    e.preventDefault()
    const nextCategory = e.currentTarget.getAttribute("data-category")
    if (nextCategory === category) {
      return false
    }
    let para = nextCategory
    switch (nextCategory) {
      case "on going":
        para = "on"
        break
      case "zen garden":
        para = "zen"
        break
      case "personal work":
        para = "personal"
        break
      default:
        break
    }
    //urlにパラメータをセットして判別
    const query = "?category=" + para
    window.history.pushState(null, null, query)
    setCategory(nextCategory)
  }

  return (
    <Layout page="project-archive">
      <Meta
        title="projects | 1moku co."
        description="hirofumi sugaを中心に庭園デザインをはじめ、庭師の視点でとらえた空間デザイン、ランドスケープデザインなどを手掛けている1moku co.のprojectsをご紹介します。"
        url="https://www.1moku.co.jp/projects/"
        type="article"
      />
      <PageTitle title="all projects" h1="true" />
      <div className="project-container">
        <ProjectCategory
          category={category}
          list={categoryList}
          click={categorySwitch}
        />
        <div className="projects-area">
          <div className="projects-area__list">
            <ProjectList category={category} articles={context} />
          </div>
        </div>
      </div>
      <div className="related">
        <ul className="related__list">
          <li className="related__item" data-scroll="">
            <Link className="related__link" to="/about">
              About us<span className="related__arrow">more</span>
            </Link>
          </li>
        </ul>
      </div>
    </Layout>
  )
}

export default Projects
